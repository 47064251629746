import { useEffect, useState } from 'react';
import './PromptDialog.scss';

interface PromptDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (prompt: string) => void;
  currentPrompt: string;
}

export function PromptDialog({ isOpen, onClose, onSave, currentPrompt }: PromptDialogProps) {
  const [prompt, setPrompt] = useState(currentPrompt);

  useEffect(() => {
    setPrompt(currentPrompt);
  }, [currentPrompt]);

  if (!isOpen) return null;

  return (
    <div className="prompt-dialog-overlay" onClick={onClose}>
      <div className="prompt-dialog" onClick={e => e.stopPropagation()}>
        <div className="prompt-dialog-header">
          <h3>Update Assistant Instructions</h3>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        
        <div className="prompt-dialog-content">
          <div className="current-prompt">
            <label>Current Instructions:</label>
            <div className="prompt-display">{currentPrompt}</div>
          </div>
          
          <div className="new-prompt">
            <label>New Instructions:</label>
            <textarea
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              placeholder="Enter new instructions for the AI assistant..."
              autoFocus
            />
          </div>
        </div>

        <div className="prompt-dialog-footer">
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button 
            className="save-button" 
            onClick={() => {
              onSave(prompt);
              onClose();
            }}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
} 