interface RateLimit {
  startTime: number;
  duration: number;
  isBlocked: boolean;
}

export class RateLimiter {
  private static limits: Map<string, RateLimit> = new Map();
  private static DAILY_LIMIT_MS = 10 * 60 * 1000; // 10 minutes in milliseconds

  static checkLimit(identifier: string): { allowed: boolean; remainingMs: number } {
    const now = Date.now();
    const limit = this.limits.get(identifier);

    // If no existing limit, create new one
    if (!limit) {
      this.limits.set(identifier, {
        startTime: now,
        duration: 0,
        isBlocked: false
      });
      return { allowed: true, remainingMs: this.DAILY_LIMIT_MS };
    }

    // Check if it's a new day (reset after 24h)
    if (now - limit.startTime >= 24 * 60 * 60 * 1000) {
      this.limits.set(identifier, {
        startTime: now,
        duration: 0,
        isBlocked: false
      });
      return { allowed: true, remainingMs: this.DAILY_LIMIT_MS };
    }

    // Check if already blocked
    if (limit.isBlocked) {
      return { allowed: false, remainingMs: 0 };
    }

    // Check remaining time
    const remainingMs = this.DAILY_LIMIT_MS - limit.duration;
    if (remainingMs <= 0) {
      limit.isBlocked = true;
      return { allowed: false, remainingMs: 0 };
    }

    return { allowed: true, remainingMs };
  }

  static updateDuration(identifier: string, durationMs: number): void {
    const limit = this.limits.get(identifier);
    if (limit) {
      limit.duration += durationMs;
      if (limit.duration >= this.DAILY_LIMIT_MS) {
        limit.isBlocked = true;
      }
    }
  }

  // Helper to get device/browser fingerprint
  static async getDeviceId(): Promise<string> {
    // This is a simple example - you might want to use a more robust fingerprinting library
    const userAgent = navigator.userAgent;
    const screenRes = `${window.screen.width}x${window.screen.height}`;
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const fingerprint = `${userAgent}-${screenRes}-${timeZone}`;
    
    // Create a hash of the fingerprint
    const msgBuffer = new TextEncoder().encode(fingerprint);
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
  }
} 